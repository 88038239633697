// Get all the tab links
const tabs = document.querySelectorAll("div sl-button")

// Get all the content sections
const sections = document.querySelectorAll("section[id]")

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const targetSectionId = entry.target.id
        tabs.forEach((tab) => {
          tab.classList.remove("highlight")
          if (tab.getAttribute("href") === `#${targetSectionId}`) {
            tab.classList.add("highlight")
          }
        })
      }
    })
  },
  { threshold: 0.1 }
)

// Observe each content section
sections.forEach((section) => {
  observer.observe(section)
})
