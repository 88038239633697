import "$styles/index.css"
// import "syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"
import './modernizr.js'

import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path.js"
setBasePath("/images");

import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
