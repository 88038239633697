
        import * as module0 from '$components/about/bio.css';import * as module1 from '$components/about/bio.js';import * as module2 from '$components/articles/next.css';import * as module3 from '$components/articles/next.js';import * as module4 from '$components/articles/page.css';import * as module5 from '$components/articles/page.js';import * as module6 from '$components/articles/previous.css';import * as module7 from '$components/articles/previous.js';import * as module8 from '$components/home/_increasing_access.css';import * as module9 from '$components/home/course.css';import * as module10 from '$components/home/course.js';import * as module11 from '$components/home/fact.js';import * as module12 from '$components/home/item.js';import * as module13 from '$components/home/search_form.css';import * as module14 from '$components/home/search_form.js';import * as module15 from '$components/navbar/menu.css';import * as module16 from '$components/navbar/menu_link.css';import * as module17 from '$components/posts/navigation.css';import * as module18 from '$components/posts/next.css';import * as module19 from '$components/posts/next.js';import * as module20 from '$components/posts/previous.css';import * as module21 from '$components/posts/previous.js';import * as module22 from '$components/shared/_footer.css';import * as module23 from '$components/shared/badge.css';import * as module24 from '$components/shared/badge.js';import * as module25 from '$components/shared/button.css';import * as module26 from '$components/shared/button.js';import * as module27 from '$components/shared/carousel.js';import * as module28 from '$components/shared/navbar.css';import * as module29 from '$components/shared/navbar.js';import * as module30 from '$components/shared/page_card.css';import * as module31 from '$components/shared/page_card.js';import * as module32 from '$components/shared/scroll_nav.css';import * as module33 from '$components/shared/scroll_nav.js'
        const modules = {
            "about/bio.css": module0,
            "about/bio.js": module1,
            "articles/next.css": module2,
            "articles/next.js": module3,
            "articles/page.css": module4,
            "articles/page.js": module5,
            "articles/previous.css": module6,
            "articles/previous.js": module7,
            "home/_increasing_access.css": module8,
            "home/course.css": module9,
            "home/course.js": module10,
            "home/fact.js": module11,
            "home/item.js": module12,
            "home/search_form.css": module13,
            "home/search_form.js": module14,
            "navbar/menu.css": module15,
            "navbar/menu_link.css": module16,
            "posts/navigation.css": module17,
            "posts/next.css": module18,
            "posts/next.js": module19,
            "posts/previous.css": module20,
            "posts/previous.js": module21,
            "shared/_footer.css": module22,
            "shared/badge.css": module23,
            "shared/badge.js": module24,
            "shared/button.css": module25,
            "shared/button.js": module26,
            "shared/carousel.js": module27,
            "shared/navbar.css": module28,
            "shared/navbar.js": module29,
            "shared/page_card.css": module30,
            "shared/page_card.js": module31,
            "shared/scroll_nav.css": module32,
            "shared/scroll_nav.js": module33,
        };
        export default modules;
      