import "@shoelace-style/shoelace/dist/components/resize-observer/resize-observer.js"
import "@shoelace-style/shoelace/dist/components/carousel/carousel.js"


function slidesPerPage(carousel, width) {
  const normalizedWidth = Math.min(width, 1280); // 1280 is the max width of the carousel
  const slideSize = 1280 / carousel.dataset.maxSlidesPerPage;
  const slides = Math.max(Math.floor(normalizedWidth / slideSize), 1);
  return slides;
}

function attachResizeListeners() {
  const resizeObservers = document.querySelectorAll("sl-resize-observer");

  resizeObservers.forEach(resizeObserver => {
    const carousel = resizeObserver.querySelector("sl-carousel");

    if (carousel) {
      resizeObserver.addEventListener("sl-resize", event => {
        const numSlides = slidesPerPage(carousel, event.detail.entries[0].contentRect.width);
        carousel.slidesPerPage = numSlides;
        carousel.navigation = (numSlides < carousel.dataset.maxSlidesPerPage);
      });
    }
  })
}

document.addEventListener("DOMContentLoaded", () => { attachResizeListeners() });
